@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
// Bootstrap
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.scss";

[class*="sidebar-light-"] {
    background-color: #FBFBFB;
}
[class*="sidebar-light-"] .nav-sidebar > .nav-item.menu-open > .nav-link,
[class*="sidebar-light-"] .nav-sidebar > .nav-item:hover > .nav-link {
    background-color: #FDF2E6;
    color: #212529;
}
[class*="sidebar-light-"] .nav-treeview > .nav-item > .nav-link.active, [class*="sidebar-light-"] .nav-treeview > .nav-item > .nav-link.active:hover {
    background-color: #FDF2E6;
    color: #212529;
}
.content-wrapper{
    background-color: #FBFBFB !important;
}
.main-sidebar{
    border-right: 1px solid #dee2e6;
}
.menu-icon{
    color: #B35E00;
}
.nav-icon{
    color: #B35E00;
}
.nav-item-text{
    color: #5D686E;
    font-size: 16px;
    font-weight: 500;
    line-height: 17.60px;
    word-wrap: break-word
}
.main-header{
    border-bottom: none;
}
.btn-blue{
    background-color: #002F6D !important;
    border-color: #002F6D !important;
    color: white !important;
}
.btn-blue:hover, .btn-blue:focus, .btn-blue:active, .btn-blue.active, .open>.dropdown-toggle.btn-blue {
    background-color: #1e5092 !important;
}
.input-blue{
    border-color: #002F6D !important;
    color: #002F6D !important;
    background-color: transparent !important;
}
.input-blue::placeholder{
    color: #002F6D !important;
}
.input-search{
    background-color: transparent !important;
    border-right: none !important;
}
.table-margin{
    margin-top: 7%;
    margin-left: 0px;
}
.active-nav-item{
    font-weight: bold;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom: 2px solid #B35E00 !important;
    background-color: transparent !important;
}
.active-tab{
    margin-top: 3%;
}
.nav-item a {
    color: #5D686E !important;
}
.validation-error {
    border-color: red;
}
.validation-error-message {
    color: red;
}
main {
    min-height: 100vh;
}
main .bg-welcome {
    min-height: 100vh;
    background-color: #002F6DF2;
    padding-top: 10vh;
}
